<ng-template #contentTemplate>
  <div class="resultlist-item-wrapper">
    <div class="!ml-0 !mr-0 mb-4 block">
      <cg-richtext
        [content]="{ text: ('location.result.headline' | transloco) + content.address, ngTemplate: 'ng-paragraph' }"
      ></cg-richtext>
    </div>
    @if (content?.primaryCtalink) {
      <div class="!ml-0 !mr-0 mb-4 block">
        <cg-ctalink [content]="content?.primaryCtalink" (clickedFn)="goToOlb($event, content)"></cg-ctalink>
      </div>
    }
    <div class="!ml-0 !mr-0 block">
      <cg-ctalink [content]="content?.detailCtalink" (clickedFn)="showDetails()"></cg-ctalink>
    </div>
    <cg-ccc-phone-info></cg-ccc-phone-info>
  </div>
</ng-template>

<ng-template #titleTemplate>
  <div class="flex flex-row items-center justify-between border-b border-cg-anthracite-light-80 p-3">
    <div class="flex flex-row items-center justify-start gap-4">
      <div
        class="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border border-cg-anthracite-light-80 font-roboto-condensed text-[16px] font-bold leading-[16px] text-cg-anthracite"
      >
        <div class="text-center">
          {{ content.distance || "< 1" }}
          <div class="text-[10px] leading-[10px]">km</div>
        </div>
      </div>

      <div class="headline text-base font-bold text-[#333e46]" data-cy="expansion-panel-title">
        {{ content?.expansionPanel?.headline }}
      </div>
    </div>
    <cg-icon
      [content]="content?.expansionPanel?.icon"
      [ngClass]="{ 'rotate-180 transform': expanded }"
      class="h-6 w-6 transition-transform duration-700"
    ></cg-icon>
  </div>
</ng-template>

<cg-expansion-panel
  [content]="content?.expansionPanel"
  [contentTemplate]="contentTemplate"
  [titleTemplate]="titleTemplate"
  [expanded]="expanded"
></cg-expansion-panel>
