import { NgTemplateOutlet } from "@angular/common";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { GoogleMap, GoogleMapsModule, MapInfoWindow, MapMarker } from "@angular/google-maps";
import { debounceTime, filter, map, take } from "rxjs/operators";
import { ConsentServiceKeys, UserCentricsService } from "@cg/consent-management";
import { ServiceCenter, ServiceCenterLocation } from "@cg/core/interfaces";
import { GoogleMapsService } from "@cg/core/services";
import { Picture, PictureComponent } from "@cg/core/ui";
import { environment } from "@cg/environments";
import { LocationsFacade } from "../../+state/locations.facade";
import { mapStyle } from "../../models/map-style.model";

@Component({
  selector: "cg-google-maps",
  templateUrl: "./google-maps.component.html",
  styleUrls: ["./google-maps.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgTemplateOutlet, GoogleMapsModule, PictureComponent]
})
export class GoogleMapsComponent implements OnInit, OnChanges {
  public destroyRef = inject(DestroyRef);
  public readonly DEFAULT_ZOOM = 9;

  @Input()
  public zoom = this.DEFAULT_ZOOM;

  @Input()
  public useOwnStyle = true;

  @Input()
  public mapHeight = "750px";

  @Input()
  public isMarkerClickable = true;

  @Input()
  public showMultipleServiceCenters = true;

  @Input()
  public infoWindowTemplateRef: TemplateRef<unknown>;

  @Input()
  public serviceCenterLocations: ServiceCenterLocation[] = [];

  @Output()
  public apiLoaded: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public markerClicked: EventEmitter<{ costCenter: string; marker: MapMarker }> = new EventEmitter<{
    costCenter: string;
    marker: MapMarker;
  }>();

  @ViewChild(GoogleMap)
  public map: GoogleMap;

  public isApiLoaded: boolean;

  public position: google.maps.LatLngLiteral = {
    lat: 51.165691,
    lng: 10.451526000000058
  };

  public options: google.maps.MapOptions = {
    disableDoubleClickZoom: true,
    disableDefaultUI: true
  };
  public markerOptions: google.maps.MarkerOptions = { draggable: false };

  public markerPositions = [];

  public fallbackPicture: Picture = {
    sizes: [
      {
        srcset: `${environment.assetPath}/locations/img_worldmap.jpg`,
        media: "(min-width: 320px)"
      }
    ],
    source: `${environment.assetPath}/locations/img_worldmap.jpg`,
    classes: "map-container fit-size",
    alt: "Google Maps not activated"
  };

  @ViewChild(MapInfoWindow, { static: false }) public infoWindow: MapInfoWindow;

  public selectedCostCenter: string;

  public constructor(
    private locationsFacade: LocationsFacade,
    private userCentricsService: UserCentricsService,
    private cdr: ChangeDetectorRef
  ) {}

  public ngOnInit() {
    if (this.useOwnStyle) {
      this.options.styles = mapStyle;
      this.options.gestureHandling = "none";
    }

    this.markerOptions.clickable = this.isMarkerClickable;
    this.options.fullscreenControl = !this.showMultipleServiceCenters;
    this.userCentricsService
      .activateService<GoogleMapsService>(ConsentServiceKeys.googleMaps)
      .apiLoaded$.pipe(debounceTime(100), takeUntilDestroyed(this.destroyRef))
      .subscribe((val: boolean) => {
        this.isApiLoaded = val;
        this.apiLoaded.emit();
        this.cdr.markForCheck();
      });

    this.locationsFacade.selectedServiceCenter$
      .pipe(
        filter((sc: ServiceCenter) => !!sc),
        map((sc: ServiceCenter) => sc.costCenter)
      )
      .subscribe((costCenter: string) => {
        this.selectedCostCenter = costCenter;
        this.cdr.markForCheck();
      });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.serviceCenterLocations = [];
    this.markerPositions = [];

    if (changes?.serviceCenterLocations?.currentValue?.length > 0) {
      this.serviceCenterLocations = changes.serviceCenterLocations.currentValue;

      if (this.serviceCenterLocations.length === 1) {
        const serviceCenter: ServiceCenterLocation = this.serviceCenterLocations[0];
        this.position = {
          lat: serviceCenter.latitude,
          lng: serviceCenter.longitude
        };
        this.loopServiceCenterLocationsListAndAddMarkers();
        this.cdr.markForCheck();
      } else {
        this.locationsFacade.userPosition$
          .pipe(
            filter((position: { lat: number; lng: number }) => !!position),
            take(1),
            takeUntilDestroyed(this.destroyRef)
          )
          .subscribe((position: { lat: number; lng: number }) => {
            this.position = position;
            this.loopServiceCenterLocationsListAndAddMarkers();

            this.zoomToFitMarkers();
            this.cdr.markForCheck();
          });
      }
    }
  }

  private loopServiceCenterLocationsListAndAddMarkers(): void {
    this.serviceCenterLocations.forEach((serviceCenterLocation: ServiceCenterLocation) => {
      this.addMarkerPosition(serviceCenterLocation);
    });
  }

  public onMarkerClick(costCenter: string, marker: MapMarker) {
    if (this.markerClicked.observers.length > 0) {
      this.markerClicked?.emit({ costCenter, marker });
      return;
    }

    if (this.useOwnStyle && !this.infoWindowTemplateRef) {
      this.locationsFacade.getServiceCentersDetails(costCenter);
      return;
    }
    this.infoWindow.open(marker);
  }

  public zoomToPosition(lat: number, lng: number, zoom?: number) {
    this.position = { lat, lng };
    this.zoom = zoom ?? this.DEFAULT_ZOOM;

    this.cdr.markForCheck();
  }

  public zoomToFitMarkers() {
    const newBoundary = new google.maps.LatLngBounds();
    const padding = 64;

    for (const marker of this.markerPositions) {
      newBoundary.extend(marker.position);
    }

    setTimeout(() => {
      this.map?.fitBounds(newBoundary, padding);
    });
  }

  private addMarkerPosition(serviceCenter: ServiceCenterLocation): void {
    this.markerPositions.push({
      position: { lat: serviceCenter.latitude, lng: serviceCenter.longitude },
      costCenter: serviceCenter.costCenter,
      address: `${serviceCenter.street} ${serviceCenter.streetNumber} ${serviceCenter.postalCode} ${serviceCenter.city}`,
      label: serviceCenter.label
    });
  }
}
