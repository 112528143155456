import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { HeadlineComponent } from "@cg/core/ui";

@Component({
  selector: "cg-location-stage",
  templateUrl: "./location-stage.component.html",
  styleUrls: ["./location-stage.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [HeadlineComponent]
})
export class LocationStageComponent {
  @Input()
  public headline: string;
}
