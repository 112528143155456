import { AsyncPipe, NgClass } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, inject, OnInit } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { Meta, Title } from "@angular/platform-browser";
import { combineLatest, Observable } from "rxjs";
import { filter, map } from "rxjs/operators";
import { SpinnerComponent } from "@cg/spinner";
import { TranslocoPipe } from "@jsverse/transloco";
import { ServiceCenterLocation } from "@cg/core/interfaces";
import { GoogleMapsService } from "@cg/core/services";
import { FooterComponent } from "@cg/footer";
import { HeaderComponent } from "@cg/header";
import { LocationsFacade } from "../../+state/locations.facade";
import { DynamicMap } from "../../interfaces/dynamic-map.interface";
import { GoogleMapsComponent } from "../google-maps/google-maps.component";
import { LocationDetailsComponent } from "../location-details/location-details.component";
import { LocationResultListComponent } from "../location-result-list/location-result-list.component";
import { LocationSearchInputComponent } from "../location-search-input/location-search-input.component";
import { LocationStageComponent } from "../location-stage/location-stage.component";

@Component({
  selector: "cg-location-page",
  templateUrl: "./location-page.component.html",
  styleUrls: ["./location-page.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgClass,
    AsyncPipe,
    TranslocoPipe,
    HeaderComponent,
    GoogleMapsComponent,
    LocationStageComponent,
    LocationSearchInputComponent,
    LocationResultListComponent,
    LocationDetailsComponent,
    FooterComponent,
    SpinnerComponent
  ]
})
export class LocationPageComponent implements OnInit {
  public destroyRef = inject(DestroyRef);
  public dynamicMap: DynamicMap;
  public hasLoadedServiceCenters = false;
  public hasUserPosition = false;
  public showServiceCenterDetails: boolean;

  public content: {
    id: string;
    controlName: string;
    placeholder: string;
    errorMessage: string;
  } = {
    id: "location-stage-search-for-sc",
    controlName: "searchServiceCenterInput",
    placeholder: "location.search",
    errorMessage: ""
  };

  public serviceCenterLocations$: Observable<ServiceCenterLocation[]>;

  public constructor(
    private readonly title: Title,
    private readonly meta: Meta,
    private readonly locationsFacade: LocationsFacade,
    private readonly googleMapsService: GoogleMapsService,
    private readonly cdr: ChangeDetectorRef
  ) {
    this.title.setTitle("Standorte im Überblick | Carglass<sup>®</sup>");
    this.meta.updateTag({
      name: "description",
      content:
        "Als Nummer 1 für Autoglas haben wir über 340 Carglass<sup>®</sup>-Standorte in Deutschland. Finden Sie jetzt das Service-Center in Ihrer Nähe!"
    });
  }

  public ngOnInit() {
    combineLatest([
      this.googleMapsService.apiLoaded$,
      this.locationsFacade.hasLoaded$,
      this.locationsFacade.showServiceCenterDetails$,
      this.locationsFacade.userPosition$
    ])
      .pipe(
        filter(
          ([hasGoogleMapsLoaded]: [boolean, boolean, boolean, google.maps.LatLngLiteral]) => !!hasGoogleMapsLoaded
        ),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(
        ([, hasLoaded, showServiceCenterDetails, userPosition]: [
          boolean,
          boolean,
          boolean,
          google.maps.LatLngLiteral
        ]) => {
          this.hasLoadedServiceCenters = hasLoaded;
          this.hasUserPosition = !!userPosition;
          this.showServiceCenterDetails = showServiceCenterDetails;
          this.cdr.detectChanges();
        }
      );

    this.serviceCenterLocations$ = this.locationsFacade.serviceCenterLocations$.pipe(
      filter(
        (serviceCenterLocations: ServiceCenterLocation[]) =>
          !!serviceCenterLocations && serviceCenterLocations.length > 0
      ),
      map((serviceCenterLocations: ServiceCenterLocation[]) => serviceCenterLocations),
      takeUntilDestroyed(this.destroyRef)
    );
  }
}
