import { createAction, props } from "@ngrx/store";
import type { ServiceCenter, ServiceCenterLocation } from "@cg/core/interfaces";

export const setUserPosition = createAction(
  "[Locations] Set User Position",
  props<{ payload: google.maps.LatLngLiteral }>()
);

export const setSelectedServiceCenterOnMapClick = createAction(
  "[Locations] Set Selected Service Center On Map Click",
  props<{ payload: ServiceCenter }>()
);

export const setSearch = createAction("[Locations] Set Search", props<{ payload: string }>());

export const setCurrentPage = createAction("[Locations] Set Current Page", props<{ payload: number }>());

export const getServiceCentersDetails = createAction(
  "[Locations] Get Service Centers Details",
  props<{ payload: string[] }>()
);

export const getServiceCenterDetailsSuccess = createAction(
  "[Locations] Get Service Centers Details Success",
  props<{ payload: ServiceCenter[] }>()
);

export const getServiceCenterDetailsFailure = createAction(
  "[Locations] Get Service Centers Details Failure",
  props<{ error: string }>()
);

export const clearServiceCenterDetails = createAction("[Locations] Clear Service Center Details");

export const getServiceCenterLocations = createAction("[Locations] Get Service Center Locations");
export const getServiceCenterLocationsSuccess = createAction(
  "[Locations] Get Service Center Locations Success",
  props<{ payload: ServiceCenterLocation[] }>()
);
export const getServiceCenterLocationsFailure = createAction(
  "[Locations] Get Service Center Locations Failure",
  props<{ error: string }>()
);
export const collapseServiceCenterLocationPin = createAction("[Locations] Collapse Service Center Location Pin");

export const toggleServiceCenterLocation = createAction(
  "[Locations] Toggle Service Center Location",
  props<{ payload: string }>()
);

export const resetServiceCentersPages = createAction("[Locations] Reset Service Centers Pages");

export const setMarkerClicked = createAction("[Locations] Set Marker Clicked", props<{ payload: string }>());

export const showServiceCenterDetails = createAction("[Locations] Show Service Center Details");

export const closeServiceCenterDetails = createAction("[Locations] Close Service Center Details");

export const isServiceCenterClosed = createAction(
  "[Locations] Is Service Center Closed",
  props<{ payload: string[] }>()
);

export const isServiceCenterClosedSuccess = createAction(
  "[Locations] Is Service Center Closed Success",
  props<{ payload: { isScClosed: boolean; costCenter: string } }>()
);
