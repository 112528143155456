@if (!isApiLoaded) {
  <div>
    <cg-picture [content]="fallbackPicture"></cg-picture>
  </div>
}
@if (isApiLoaded) {
  <div>
    <google-map [height]="mapHeight" [width]="'100%'" [options]="options" [zoom]="zoom" [center]="position">
      @for (markerPosition of markerPositions; track markerPosition) {
        <map-marker
          #marker="mapMarker"
          [position]="markerPosition.position"
          [title]="markerPosition.title"
          [label]="markerPosition.label"
          [options]="markerOptions"
          (mapClick)="onMarkerClick(markerPosition.costCenter, marker)"
        ></map-marker>
      }
      @if (!useOwnStyle && infoWindowTemplateRef) {
        <map-info-window>
          <ng-container *ngTemplateOutlet="infoWindowTemplateRef"></ng-container>
        </map-info-window>
      }
    </google-map>
  </div>
}
