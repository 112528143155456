<cg-header></cg-header>

<div class="content">
  <cg-google-maps [serviceCenterLocations]="serviceCenterLocations$ | async"></cg-google-maps>

  @if (!hasLoadedServiceCenters && !showServiceCenterDetails && !hasUserPosition) {
    <cg-location-stage [headline]="'location.stageHeadline' | transloco"></cg-location-stage>
  }

  <div
    class="search-wrapper"
    [ngClass]="{
      'no-results': !hasLoadedServiceCenters && !hasUserPosition,
      results: hasLoadedServiceCenters || hasUserPosition
    }"
  >
    <cg-location-search-input [content]="content"></cg-location-search-input>

    @if (hasLoadedServiceCenters && !showServiceCenterDetails) {
      <cg-location-result-list></cg-location-result-list>
    }
  </div>

  @if (showServiceCenterDetails) {
    <cg-location-details></cg-location-details>
  }

  <cg-footer></cg-footer>
</div>
<cg-spinner></cg-spinner>
