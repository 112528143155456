@if (hasLoadedServiceCenters) {
  <div class="resultlist-wrapper">
    @if (pageItems.length && pageItems[0].distance <= 25) {
      @for (serviceCenter of pageItems; track serviceCenter) {
        <cg-location-result-list-item
          [content]="serviceCenter"
          (click)="clicked(serviceCenter)"
        ></cg-location-result-list-item>
      }
    } @else {
      <cg-location-no-results [alternativeServiceCenters]="pageItems"></cg-location-no-results>
    }

    @if (maxPage > 1) {
      <div class="locationsearch-footer">
        <div class="wrapper-backward-arrow">
          @if (showBackwardButton) {
            <cg-icon class="backward-arrow h-6 w-6" [content]="icon" (click)="goBackward()"></cg-icon>
          }
        </div>
        <span class="page-text"
          >{{ "location.pagination.page" | transloco }} {{ currentPage + 1 }}
          {{ "location.pagination.of" | transloco }} {{ maxPage }}</span
        >
        @if (showForwardButton) {
          <cg-icon class="forward-arrow h-6 w-6" [content]="icon" (click)="goForward()"></cg-icon>
        }
      </div>
    }
  </div>
}
