import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { of } from "rxjs";
import { catchError, filter, map, mergeMap, tap, withLatestFrom } from "rxjs/operators";
import { ServiceCenter, ServiceCenterLocation } from "@cg/core/interfaces";
import { UnifiedError } from "@cg/core/types";
import { errorToString } from "@cg/core/utils";
import { OpeningHour, ServiceCenterService } from "@cg/shared";
import * as LocationsActions from "./locations.actions";
import { LocationsFacade } from "./locations.facade";

@Injectable()
export class LocationsEffects {
  public userPosition$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationsActions.setUserPosition),
      map(() => LocationsActions.getServiceCenterLocations())
    )
  );

  public loadServiceCenterByRadius$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationsActions.getServiceCenterLocations),
      withLatestFrom(this.locationsFacade.userPosition$),
      map(([_, userPosition]: [Action, { lat: number; lng: number }]) => userPosition),
      mergeMap((payload: { lat: number; lng: number }) =>
        this.serviceCenterService
          .getServiceCentersByRadius({
            lat: payload.lat,
            lng: payload.lng
          })
          .pipe(
            map((serviceCenterLocations: ServiceCenterLocation[]) =>
              LocationsActions.getServiceCenterLocationsSuccess({
                payload: serviceCenterLocations
              })
            ),
            catchError((error: UnifiedError) =>
              of(LocationsActions.getServiceCenterLocationsFailure({ error: errorToString(error) }))
            )
          )
      )
    )
  );

  public getServiceCenterLocationSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationsActions.getServiceCenterDetailsSuccess),
      map(() => LocationsActions.showServiceCenterDetails())
    )
  );

  public getServiceCenterDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationsActions.getServiceCentersDetails),
      map(({ payload }: { payload: string[] }) => payload),
      mergeMap((payload: string[]) =>
        this.serviceCenterService.getServiceCenters(payload).pipe(
          mergeMap((serviceCenter: ServiceCenter[]) => [
            LocationsActions.getServiceCenterDetailsSuccess({ payload: serviceCenter }),
            LocationsActions.toggleServiceCenterLocation({ payload: payload[0] })
          ]),
          catchError((error: UnifiedError) =>
            of(LocationsActions.getServiceCenterDetailsFailure({ error: errorToString(error) }))
          )
        )
      )
    )
  );

  public toggleServiceCenterLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationsActions.toggleServiceCenterLocation),
      withLatestFrom(this.locationsFacade.serviceCenterLocations$),
      filter(([{ payload }, serviceCenters]: [{ payload: string }, ServiceCenter[]]) => !!payload && !!serviceCenters),
      map(([{ payload }, serviceCenters]: [{ payload: string }, ServiceCenter[]]) => {
        const index = serviceCenters.findIndex(({ costCenter }: { costCenter: string }) => costCenter === payload) + 1;
        return Math.ceil(index / 5) - 1;
      }),
      withLatestFrom(this.locationsFacade.selectedServiceCenter$),
      mergeMap(([page, selectedSc]: [number, ServiceCenter]) => {
        const actions: Action<string>[] = [LocationsActions.setCurrentPage({ payload: page })];

        if (selectedSc) {
          actions.push(LocationsActions.showServiceCenterDetails());
        }

        return actions;
      })
    )
  );

  public getOpeningHoursForServiceCenter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationsActions.isServiceCenterClosed),
      map(({ payload }: { payload: string[] }) => payload),
      mergeMap((payload: string[]) =>
        this.serviceCenterService.getServiceCenters(payload).pipe(
          map((serviceCenter: ServiceCenter[]) => ({
            isScClosed: serviceCenter[0].openingHours.every((openingHour: OpeningHour) => !openingHour.start),
            costCenter: serviceCenter[0].costCenter
          })),
          mergeMap(({ isScClosed, costCenter }: { isScClosed: boolean; costCenter: string }) => [
            LocationsActions.isServiceCenterClosedSuccess({
              payload: { isScClosed, costCenter }
            })
          ])
        )
      )
    )
  );

  public isServiceCenterClosedSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LocationsActions.isServiceCenterClosedSuccess),
        map(({ payload }: { payload: { isScClosed: boolean; costCenter: string } }) => payload.costCenter),
        tap((costCenter: string) => {
          this.locationsFacade.toggleServiceCenterLocation(costCenter);
        })
      ),
    {
      dispatch: false
    }
  );

  public constructor(
    private actions$: Actions,
    private serviceCenterService: ServiceCenterService,
    private locationsFacade: LocationsFacade
  ) {}
}
