<div class="text-input-wrapper">
  <div class="floating">
    <input
      #input
      [id]="content.id"
      class="input"
      [name]="content.controlName"
      [placeholder]="content.placeholder | transloco"
      [value]="locationSearchValue$ | async"
      (change)="textChanged($event)"
    />
    <label [for]="content.controlName" class="label" id="{{ content.placeholder | transloco }}"></label>
    <cg-icon class="icon suffix-icon w-6" [content]="icon"></cg-icon>
    <p class="error-message">{{ content.errorMessage }}</p>
  </div>
</div>
