import { NgClass } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { lastValueFrom } from "rxjs";
import { first } from "rxjs/operators";
import { TranslocoPipe } from "@jsverse/transloco";
import { SharedFacade } from "@cg/carglass-shared-state";
import { IconComponent, RichtextComponent } from "@cg/core/ui";
import { CccPhoneInfoComponent, CtalinkComponent, ExpansionPanelComponent } from "@cg/shared";
import { LocationsFacade } from "../../+state/locations.facade";
import { LocationsearchResultlistItem } from "../../interfaces/locationsearch-resultlist-item.interface";

@Component({
  selector: "cg-location-result-list-item",
  templateUrl: "./location-result-list-item.component.html",
  styleUrls: ["./location-result-list-item.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TranslocoPipe,
    RichtextComponent,
    CtalinkComponent,
    CccPhoneInfoComponent,
    ExpansionPanelComponent,
    IconComponent,
    NgClass
  ]
})
export class LocationResultListItemComponent implements OnInit {
  @Input()
  public content!: LocationsearchResultlistItem;

  public expanded = false;

  public constructor(
    private sharedFacade: SharedFacade,
    private locationsFacade: LocationsFacade,
    private cdr: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this.locationsFacade.toggleServiceCenterLocation$.subscribe((kst: string) => {
      this.expanded = this.content.costCenter === kst;
      this.cdr.markForCheck();
    });
  }

  public showDetails(): void {
    this.locationsFacade.getServiceCentersDetails(this.content.costCenter);
  }

  public async goToOlb(event: MouseEvent, content: LocationsearchResultlistItem): Promise<void> {
    event.stopPropagation();
    event.preventDefault();

    this.sharedFacade.setCurrentServiceCenter({
      location: await lastValueFrom(this.locationsFacade.search$.pipe(first())),
      costCenter: content.costCenter
    });
  }
}
