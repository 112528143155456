<div class="locationdetails-background"></div>
<div class="locationdetails-wrapper">
  <div class="locationdetails-header">
    <div class="locationdetails-header-wrapper-description">
      <cg-icon class="locationdetails-header-icon" [content]="locationIcon"></cg-icon>
      <div class="locationdetails-header-address">
        <cg-headline
          class="service-center-headline"
          [content]="('location.headline.name' | transloco) + ' ' + content?.address?.city"
          [type]="headlineTypes.h1"
        ></cg-headline>
        <cg-headline
          [content]="
            content?.address?.street +
            ' ' +
            content?.address?.streetNumber +
            ', ' +
            content?.address?.postalCode +
            ' ' +
            content?.address?.city
          "
          [type]="headlineTypes.h3"
        ></cg-headline>
      </div>
    </div>
    <div class="locationdetails-header-wrapper-buttons">
      <cg-cta
        [content]="appointmentCta"
        [translateContent]="true"
        [disabled]="isScClosed"
        (clickedFn)="goToOlb($event, content)"
      ></cg-cta>
      <cg-cta [content]="routeCta" [translateContent]="true"></cg-cta>
    </div>
    @if (isScClosed) {
      <div class="locationdetails-is-closed-banner">
        <span class="locationdetails-is-closed-banner-span">{{ "location.closed" | transloco }}</span>
      </div>
    }
    <cg-ctaicon
      class="locationdetails-close [&>a>cg-icon>mat-icon>svg>g>g>g>g>g]:stroke-cg-anthracite"
      (click)="onClose()"
      [content]="closeCta"
    ></cg-ctaicon>
  </div>
  <div class="locationdetails-content">
    <div class="locationdetails-content-services">
      @if (isSmallMobile) {
        <cg-table class="locationdetails-content-services-opening-hours" [content]="openingHours"></cg-table>
      }
      <cg-headline
        class="service-center-headline"
        [content]="'location.service.headline' | transloco"
        [type]="headlineTypes.h3"
      ></cg-headline>
      <cg-definitionlist [content]="services"></cg-definitionlist>
      <div class="locationdetails-content-services-buttons">
        <cg-cta [content]="routeCta" [translateContent]="true" [disabled]="isScClosed"></cg-cta>
        <cg-cta
          [content]="appointmentCta"
          [translateContent]="true"
          [disabled]="isScClosed"
          (clickedFn)="goToOlb($event, content)"
        ></cg-cta>
      </div>
    </div>
    <div class="locationdetails-content-informations">
      @if (isSmallMobile === false) {
        <cg-table [content]="openingHours"></cg-table>
      }
      <cg-headline [content]="'location.service.list.headline' | transloco" [type]="headlineTypes.h3"></cg-headline>
      <cg-list [content]="serviceList"></cg-list>
      <cg-richtext [content]="notes"></cg-richtext>
      <cg-ccc-phone-info></cg-ccc-phone-info>
    </div>
  </div>
</div>
