import { Injectable } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { ServiceCenter } from "@cg/core/interfaces";
import * as LocationsActions from "../+state/locations.actions";
import * as fromLocations from "./locations.reducer";
import * as LocationsSelectors from "./locations.selectors";

@Injectable({
  providedIn: "root"
})
export class LocationsFacade {
  public userPosition$ = this.store.pipe(select(LocationsSelectors.getUserPosition));
  public search$ = this.store.pipe(select(LocationsSelectors.getSearch));
  public currentPage$ = this.store.pipe(select(LocationsSelectors.getCurrentPage));
  public hasLoaded$ = this.store.pipe(select(LocationsSelectors.hasLoaded));
  public serviceCenterLocations$ = this.store.pipe(select(LocationsSelectors.getServiceCenterLocations));
  public toggleServiceCenterLocation$ = this.store.pipe(select(LocationsSelectors.getToggleServiceCenterLocation));
  public showServiceCenterDetails$ = this.store.pipe(select(LocationsSelectors.showServiceCenterDetails));
  public selectedServiceCenter$ = this.store.pipe(select(LocationsSelectors.getSelectedServiceCenter));
  public isGivenServiceCenterClosed$ = this.store.pipe(select(LocationsSelectors.isServiceCenterClosed));

  public constructor(private store: Store<fromLocations.LocationsPartialState>) {}

  public checkIfScIsClosedAndToggle(kst: string) {
    this.store.dispatch(LocationsActions.isServiceCenterClosed({ payload: [kst] }));
  }

  public getServiceCentersDetails(kst: string) {
    this.store.dispatch(LocationsActions.getServiceCentersDetails({ payload: [kst] }));
  }

  public setUserLocation(location: google.maps.LatLngLiteral) {
    this.store.dispatch(LocationsActions.setUserPosition({ payload: location }));
  }

  public setSelectedServiceCenterOnMapClick(sc: ServiceCenter) {
    this.store.dispatch(LocationsActions.setSelectedServiceCenterOnMapClick({ payload: sc }));
  }

  public setSearch(search: string) {
    this.store.dispatch(LocationsActions.setSearch({ payload: search }));
  }

  public setCurrentPage(page: number) {
    this.store.dispatch(LocationsActions.setCurrentPage({ payload: page }));
  }

  public toggleServiceCenterLocation(kst: string) {
    this.store.dispatch(LocationsActions.toggleServiceCenterLocation({ payload: kst }));
  }

  public closeServiceCenterDetails() {
    this.store.dispatch(LocationsActions.closeServiceCenterDetails());
  }

  public clearSelectedServiceCenter() {
    this.store.dispatch(LocationsActions.clearServiceCenterDetails());
  }
}
