/// <reference types="@types/googlemaps" />

import { AsyncPipe } from "@angular/common";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  NgZone,
  OnInit,
  ViewChild
} from "@angular/core";
import { Observable } from "rxjs";
import { debounceTime, filter } from "rxjs/operators";
import { TranslocoPipe } from "@jsverse/transloco";
import { GoogleMapsService } from "@cg/core/services";
import { IconComponent } from "@cg/core/ui";
import { IS_BROWSER_PLATFORM } from "@cg/core/utils";
import { searchIcon } from "@cg/icon";
import { getGooglePlacesDisplayName } from "@cg/shared";
import type { Icon } from "@cg/content-api/typescript-interfaces";
import { LocationsFacade } from "../../+state/locations.facade";

@Component({
  selector: "cg-location-search-input",
  templateUrl: "./location-search-input.component.html",
  styleUrls: ["./location-search-input.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AsyncPipe, TranslocoPipe, IconComponent]
})
export class LocationSearchInputComponent implements OnInit {
  private _autocomplete: google.maps.places.Autocomplete;

  public autoCompleteOptions: google.maps.places.AutocompleteOptions;
  public locationSearchValue$: Observable<string> = this.locationsFacade.search$;

  public icon: Icon = searchIcon;

  @Input() public content: {
    id: string;
    controlName: string;
    placeholder: string;
    errorMessage: string;
  };

  @ViewChild("input", { static: true })
  public inputField: ElementRef;

  public constructor(
    @Inject(IS_BROWSER_PLATFORM) private readonly isBrowser: boolean,
    private cdr: ChangeDetectorRef,
    private ngZone: NgZone,
    private locationsFacade: LocationsFacade,
    private googleMapsService: GoogleMapsService
  ) {
    this.autoCompleteOptions = {
      componentRestrictions: { country: "DE" },
      types: ["geocode"],
      fields: ["formatted_address", "address_components", "geometry"]
    };
  }

  public ngOnInit(): void {
    if (this.isBrowser) {
      this.initAutoCompleteSearchForServiceCenters();
    }
  }

  public textChanged(event: { target: { value: string } }): void {
    this.locationsFacade.setSearch(event.target.value);
  }

  public initAutoCompleteSearchForServiceCenters(): void {
    this.googleMapsService.apiLoaded$
      .pipe(debounceTime(100))
      .pipe(filter((val: boolean) => !!val))
      .subscribe(() => {
        this._autocomplete = new google.maps.places.Autocomplete(
          this.inputField.nativeElement,
          this.autoCompleteOptions
        );

        google.maps.event.addListener(this._autocomplete, "place_changed", () => {
          this.ngZone.run(() => {
            const place = this._autocomplete.getPlace();
            this.invokeEvent(place);
          });
        });

        this.cdr.markForCheck();
      });
  }

  protected invokeEvent(place: google.maps.places.PlaceResult): void {
    if (place && place.address_components) {
      this.handleFormattedAddress(getGooglePlacesDisplayName(place as unknown as google.maps.GeocoderResult));
      this.locationsFacade.setUserLocation({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng()
      });
      this.locationsFacade.setCurrentPage(0);
      this.cdr.markForCheck();
    } else {
      const service = new google.maps.places.AutocompleteService();
      service.getPlacePredictions(
        {
          input: this.inputField.nativeElement.value,
          componentRestrictions: { country: "de" }
        },
        this.placePredictionCallBack.bind(this)
      );
    }
  }

  private placePredictionCallBack(
    autocompletePredictions: google.maps.places.AutocompletePrediction[],
    _placesServicesStatus: google.maps.places.PlacesServiceStatus
  ): void {
    if (autocompletePredictions && autocompletePredictions.length > 0) {
      const geocoder = new google.maps.Geocoder();

      geocoder.geocode({ placeId: autocompletePredictions[0].place_id }, this.getGeoCoderCallback.bind(this));
    }
  }

  private getGeoCoderCallback(results: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus): void {
    if (status === google.maps.GeocoderStatus.OK) {
      const firstResult = results[0];

      this.handleFormattedAddress(getGooglePlacesDisplayName(firstResult));
      this.locationsFacade.setUserLocation({
        lat: firstResult.geometry.location.lat(),
        lng: firstResult.geometry.location.lng()
      });
    }
  }

  private handleFormattedAddress(formattedAddress: string): void {
    this.inputField.nativeElement.value = formattedAddress;
    this.locationsFacade.setSearch(formattedAddress);
  }
}
