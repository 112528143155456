import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { TranslocoPipe } from "@jsverse/transloco";
import { IconComponent } from "@cg/core/ui";
import { pinNoIcon } from "@cg/icon";
import { LocationsFacade } from "../../+state/locations.facade";
import { LocationsearchResultlistItem } from "../../interfaces/locationsearch-resultlist-item.interface";
import { LocationResultListItemComponent } from "../location-result-list-item/location-result-list-item.component";

@Component({
  selector: "cg-location-no-results",
  standalone: true,
  imports: [TranslocoPipe, IconComponent, LocationResultListItemComponent],
  templateUrl: "./location-no-results.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LocationNoResultsComponent {
  @Input() public alternativeServiceCenters: LocationsearchResultlistItem[];

  public pinNoIcon = pinNoIcon;

  public constructor(private locationsFacade: LocationsFacade) {}

  public clicked(serviceCenter: LocationsearchResultlistItem): void {
    this.locationsFacade.checkIfScIsClosedAndToggle(serviceCenter.costCenter);
  }
}
